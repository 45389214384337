import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import { getInvoices } from "../reducers/invoicesReducer";
import CarrierInvoice from "./CarrierInvoice";

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  formControl: {
    border: "1px solid #dadde9"
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: "auto"
  }
})
);

export default function CarrierInvoices(props) {
  const classes = useStyles();

  const [isLoading, setisLoading] = React.useState(false);
  const [invoiced, setInvoiced] = React.useState("Not Invoiced");
  const [noReference, setNoReference] = React.useState(false);

  const dispatch = useDispatch();
  const invoicesState = useSelector(state => state.invoices);
  if (invoicesState.isLoading != isLoading)
    setisLoading(invoicesState.isLoading);
  const onManuallyRefresh = () => {
    dispatch(getInvoices(props.carrier_name, invoiced, noReference));
  };
  const fetchShipments = async () => {
    dispatch(getInvoices(props.carrier_name, invoiced));
  };
  useEffect(() => {
    if (invoicesState.list.length === 0 && !invoicesState.isLoading)
      fetchShipments();
  }, []);

  const handleChange = event => {
    setInvoiced(event.target.value);
    // props.setInvoiced(event.target.checked);
    props.setNoReference(props.carrier_name, event.target.value, noReference);
  };
  const handleNoReference = event => {
    setNoReference(event.target.checked);
    props.setNoReference(props.carrier_name, invoiced, event.target.checked);
  };

  const data = invoicesState.list.map(invoice => {
    return { ...invoice };
  });

  return (
    <React.Fragment>
      <MaterialTable
        options={{
          grouping: true,
          filtering: true,
          rowStyle: rowData => ({
            backgroundColor: rowData.missing_document == 1? 'red':'white'
          })
        }}
        isLoading={isLoading}
        title={`${props.carrier_name} Invoices`}
        columns={[
          { title: "Invoice", field: "invoice_no", grouping: false },
          { title: "Tracking", field: "tracking_no" },
          { title: "Reference", field: "reference_no",
          render: rowData=><TextField value={rowData.reference_no.length > 0 ? rowData.reference_no:rowData.name2}
             />
          },
          { title: "Customer", field: "receiver_name" },
          { title: "Sender", field: "sender_name", grouping: false },
          { title: "Declaration", field: "declaration_no",
            render: rowData=><TextField value={rowData.declaration_no?.length > 0 ? rowData.declaration_no:
              rowData.no_declaration ==1 ? "Not Needed": ""}
               />
          },
          {
            title: "Charges",
            field: "xc1_charge",
            grouping: false,
            render: rowData => (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <ul>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(index => {
                        if (rowData[`xc${index}_charge`] > 0)
                          return (
                            <li>
                              <em>
                                {rowData[`xc${index}_name`]}{" "}
                                {rowData[`xc${index}_charge`]}
                              </em>
                            </li>
                          );
                      })}
                    </ul>
                  </React.Fragment>
                }
              >
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <InfoIcon />
                </IconButton>
              </HtmlTooltip>
            )
          }
        ]}
        actions={[
          {
            icon: "refresh",
            tooltip: "Refresh",
            isFreeAction: true,
            onClick: event => {
              onManuallyRefresh();
            }
          },
          {
            icon: () => (
              // <FormGroup row>
              //   <FormControlLabel
              //     control={
              //       <Checkbox
              //         checked={checked}
              //         onChange={handleChange}
              //         value="Invoiced"
              //       />
              //     }
              //     label="Invoiced"
              //   />
              // </FormGroup>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Invoiced</FormLabel>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender1"
                  value={invoiced}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Invoiced"
                    control={<Radio />}
                    label="Invoiced"
                  />
                  <FormControlLabel
                    value="Not Invoiced"
                    control={<Radio />}
                    label="Not Invoiced"
                  />
                  <FormControlLabel
                    value="All"
                    control={<Radio />}
                    label="All"
                  />
                </RadioGroup>
              </FormControl>
            ),
            isFreeAction: true
          },
          {
            icon: () => (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={noReference}
                      onChange={handleNoReference}
                      value="No Reference"
                    />
                  }
                  label="No Reference"
                />
              </FormGroup>
            ),
            isFreeAction: true
          }
        ]}
        data={data}
        detailPanel={[
          {
            icon: "visibility",
            tooltip: "Edit account",
            render: rowData => {
              return <CarrierInvoice carrier_name={props.carrier_name} {...rowData} />;
            }
          }
        ]}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
    </React.Fragment>
  );
}
